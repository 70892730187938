









































































  .viewArt{
    /*.el-dialog__header {*/
    /*  justify-content: center !important;*/
    /*}*/
    /*.el-dialog__title {*/
    /*  font-size: 22px !important;*/
    /*}*/
  }
  .art_content {
    position: relative;
    margin: auto;
    .ql-container.ql-snow{
      border: none!important;
    }
    .art-time{
      text-align: right;
      margin-bottom: 10px;
    }
    .readnum{
      margin-top: 20px;
    }
    .act_title{
      text-align: center;
      font-size: 20px;
      margin-bottom: 15px;
      font-weight: bold;
    }
}
