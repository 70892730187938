













































































































































































































































































































































































strong{
  font-weight:bold!important;
}
em{
  font-style: italic!important;
}
.edarticle{
  width: 1200px!important;
}
.article {
  .quill-editor{
    margin-top: -27px;
  }
  .el-dialog__body {
    overflow-x: auto;
  }
  .ql-editor.ql-blank,
  .ql-editor {
    height: 420px;
  }
  .el-upload--picture-card {
    background-color: #fbfdff;
    border: 1px dashed #c0ccda;
    border-radius: 6px;
    box-sizing: border-box;
    width: 108px;
    height: 108px;
    line-height: 106px;
    vertical-align: top;
  }
  .el-loading-spinner i{
    color: #28cccc;
  }
  .el-loading-text{
    color: #28cccc;
  }
  .footer{
    text-align: center;
    margin-top: 42px;
  }
  .view_art{
    cursor: pointer;
    width: 30px;
  }
}
